import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/e-bookPost-sections/Banner"
import DetailSection from "../components/e-bookPost-sections/DetailSection"
import MainLayout from "../layouts/MainLayout"

const EbookTemplate = ({ data, pageContext }) => {
  const {
    title,
    subTitle,
    toc_title,
    toc_desc,
    title_ex,
    desc,
    heroImg,
    pdfLink,
  } = data?.strapiEbook
  const image = getImage(heroImg?.localFile)

  return (
    <MainLayout bgChanged={false}>
      <Banner
        content={subTitle}
        image={image}
        title={title}
        slug={pageContext?.slug}
        sourceUrl={pdfLink}
      />
      <DetailSection ebookPage={{ toc_title, toc_desc, title_ex, desc }} />
      <ContactSales />
    </MainLayout>
  )
}

export const query = graphql`
  query getEbooks($id: String) {
    strapiEbook(id: { eq: $id }) {
      title
      subTitle
      title_ex
      desc
      toc_title
      toc_desc: childStrapiEbookTocTextnode {
        toc
      }
      heroImg {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
        url
      }
      pdfLink
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`

export default EbookTemplate

export const Head = ({ data }) => {
  const { seo, heroImg } = data?.strapiEbook
  return (
    <SEORevamp
      title={seo?.metaTitle}
      description={seo?.metaDescription}
      image={heroImg?.url}
    />
  )
}
