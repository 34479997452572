import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Spinner from "react-bootstrap/Spinner"
import * as styles from "./Banner.module.scss"
import PdfForm from "./downloadPdfForm"

const Banner = ({ image, title, content, sourceUrl, slug }) => {
  const isSSR = typeof window === "undefined"

  return (
    <section className={styles.section}>
      <Container>
        <Row className="align-content-center">
          <Col lg={6}>
            <h1 className="text-uppercase">{title}</h1>
            <p className="pb-3">{content}</p>
            {!isSSR && (
              <React.Suspense
                fallback={
                  <div className="text-center">
                    <Spinner
                      variant="light"
                      size="sm"
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                }
              >
                <PdfForm sourceUrl={sourceUrl} eBookName={title} slug={slug} />
              </React.Suspense>
            )}
          </Col>
          <Col lg={6} className="pt-5 pt-lg-0">
            <GatsbyImage
              placeholder="blurred"
              decoding="async"
              loading="lazy"
              image={image}
              alt={title}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Banner
