import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./detailSection.module.scss"

const Banner = ({ ebookPage }) => {
  return (
    <section>
      <Container>
        <Row className="align-content-center">
          <Col md={6}>
            <h3 className="main-heading-36">{ebookPage?.toc_title}</h3>
            <div
              className={styles.leftBullets}
              dangerouslySetInnerHTML={{
                __html: ebookPage?.toc_desc?.toc,
              }}
            />
          </Col>
          <Col md={6}>
            <h3 className="main-heading-36">{ebookPage?.title_ex}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: ebookPage?.desc,
              }}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Banner
